import * as Sentry from '@sentry/gatsby';

import { LOGGING_ENABLED } from './src/consts/environmental';

Sentry.init({
  /*
   * We're using the enabled option here instead of conditionally loading the plugin since
   * using a different number of plugins between different builds causes a cache rejection.
   * This method allows CI caches to be shared between preview/staging/production builds.
   */
  enabled: LOGGING_ENABLED,
  dsn: 'https://9c7d65c6d1554854ac04c861aaf64a10@o277339.ingest.sentry.io/6660214',
  tracesSampleRate: 0.2,
  beforeSend(event, hint) {
    let error = hint.originalException;
    // @ts-expect-error
    if ('message' in error) {
      error = error.message;
    } else {
      error = '';
    }

    const ignoredErrorPatterns = [
      /loading chunk \d* failed./i,
      /Minified React error./i,
      /Hydration failed because the initial UI does not match what was rendered on the server/i,
      /There was an error while hydrating/i,
    ];

    if (
      ignoredErrorPatterns.some(
        (pattern) => error && pattern.test(<string>error),
      )
    ) {
      return null;
    }

    return event;
  },
  ignoreErrors: ['Minified React error', 'Loading chunk'],
});
